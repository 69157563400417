
.sidebar {
  background: #ffffff;
  padding: 16px;
}


.sidebar .sidebar-nav {
  width: 100%;
}

.sidebar .nav {
  width: 100%;
}


.sidebar .nav-link {
  display: block;
  padding: 1.05rem 1rem;
  color: #535353;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  background: #ffffff;
  border-radius: 7px;
}

.sidebar .nav-link:hover {
  color: #363636;
  background: #f0f0f0;
}

.sidebar .nav-link:hover .nav-icon {
  color: #363636;
}

.sidebar .nav-link.active {
  color: #f72c2c;
  background: #ffdcdc;
}

.sidebar .nav-link.active .nav-icon {
  color: #f72c2c;
}

.sidebar .nav-dropdown.open {
  background: #ffffff;
}

.sidebar .nav-dropdown.open .nav-link {
  color: #535353;
  border-left: 0;
}

.sidebar .nav-dropdown-toggle::before {
  color: #535353;
}

.sidebar .nav-link:hover.nav-dropdown-toggle::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2373818f' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.sidebar .sidebar-minimizer {
  background-color: #ffffff;
  border-top: 1px solid #3333334d;
}

html:not([dir=rtl]) .sidebar {
  margin-left: -225px;
}

@media (min-width: 992px){
  .sidebar-fixed .sidebar {
    width: 255px;
  }
  html:not([dir=rtl]) .sidebar {
      margin-left: -255px;
  }

  html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir=rtl]) .sidebar-show.sidebar-fixed .main, html:not([dir=rtl]) .sidebar-show.sidebar-fixed .app-footer {
      margin-left: 255px;
  }

  .sidebar-minimized.sidebar-fixed .sidebar {
    width: 65px;
  }

  html:not([dir=rtl]) .sidebar-minimized .sidebar {
    margin-left: -65px;
  }
  html:not([dir=rtl]) .sidebar-lg-show .sidebar, html:not([dir=rtl]) .sidebar-show .sidebar {
    margin-left: 0;
  }
  html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .main, html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .app-footer, html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-minimized .main, html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
    margin-left: 65px;
  }

  .sidebar .sidebar-minimizer:hover::before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2373818f' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  }
  .sidebar .sidebar-minimizer:hover {
    background-color: #fff2f2;
  }

  .sidebar-minimized .sidebar .sidebar-minimizer {
    background-color: #ffffff;
  }
  .sidebar-minimized .sidebar .sidebar-minimizer:hover {
    background-color: #fff2f2;
  }

  .sidebar-minimized .sidebar .nav-item:hover > .nav-link {
    background: #f0f0f0;
  }

  .sidebar-minimized .sidebar .nav-item:hover > .nav-link.active {
    background: #ffdcdc;
  }
  .sidebar-minimized .sidebar .nav-item:hover > .nav-link.active .nav-icon {
    color: #f72c2c;
  }
  .sidebar-minimized .sidebar .nav-item:hover > .nav-link .nav-icon {
    color: #363636;
  }

  .sidebar-minimized .sidebar .nav-link:hover {
    width: 255px;
    background: #ffffff;
  }
  .sidebar-minimized .sidebar .nav > .nav-dropdown {
    background-color: #ffffff;
  }
  .sidebar-minimized .sidebar .nav > .nav-dropdown > .nav-dropdown-items {
    background: #ffffff;
  }
  .sidebar-minimized .sidebar .nav-dropdown-items .nav-item {
    width: 100%;
  }
  .sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link {
    width: 255px;
  }

  .sidebar-minimized .sidebar .nav > .nav-dropdown:hover {
    background: #ffffff;
    width: 255px;
  }
}

@media (max-width: 991.98px){
  .sidebar {
      width: 225px;
  }
}