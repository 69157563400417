.card-upgrade-member .card-header-old {
  padding-top: 0.4rem;
  padding-left: 0.8rem;
  padding-bottom: 0.4rem;
  padding-right: 0.8rem;
  margin-bottom: 0;
  font-size: 20px;
  background-color: red;
  color: white;
  border-bottom: 0px transparent;
}

.card-upgrade-member .card-header-new {
  padding: 1.25rem 0;
  margin-bottom: 0;
  background-color: transparent;
  font-weight: 100;
  border-bottom: 1px solid #c8ced3;
  font-size: 16px;
  font-family: sans-serif;
}