/*Profil Section*/
.pp {
  width: 60px;
}
.nm-profile {
  font-size: 18px;
  font-weight: 600;
}
.role-user {
  font-size: 14px;
  font-weight: 300;
}

.btn-active {
  color: #fff;
  background: linear-gradient(90deg, #f8511e, #99042a);
  box-shadow: 0 2px 10px rgba(0,0,0,.2);
}

.btn-ubah-password {
  background-color: orangered;
  color: #ffffff;
}
.btn-ubah-password:hover {
  background-color: rgb(255, 107, 53);
  color: #ffffff;
}

.cover-link {
  border: 2px solid #009688;
}

.link-referral {
  background-color: #f4fff4;
  padding: 5px;
}

.link-referral input[type="text"] {
  background: transparent;
  border: 0;
  color: #076169;
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.copy-link-referral {
    cursor: pointer;
    display: inline-block;
    background-color: #076169;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 5px;
}

.copy-link-referral:hover, .copy-link-referral:focus, .copy-link-referral:active {
  background-color: #05454a;
}

.bank-information .nav-tabs .nav-link.active {
  background: #f4fff4;
}

.bank-information .tab-content > .active {
  background-color: #f4fff4;
}

.bank-information .table {
  background-color: #fff;
}