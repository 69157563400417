// Here you can add other styles
@import "card";
@import "sidebar";
@import "canvas";
@import "cursor";
@import "pagination";
@import "table";
@import "custom_form";

.main{
  background-color: #e4e5e6;
}

#emitra {
  background-color: white;
}

.fw-bold {
  font-weight: 600;
}
.app-header {
  height: 65px;
}

.app-header .navbar-brand {
  width: 255px;
}

.img-avatar {
  width: 60px;
}

.b-application .elevation {
  -webkit-box-shadow: 0 0 0 0 rgba(0,0,0,.2),0 0 0 0 rgba(0,0,0,.14),0 0 0 0 rgba(0,0,0,.12)!important;
  box-shadow: 0 0 0 0 rgba(0,0,0,.2),0 0 0 0 rgba(0,0,0,.14),0 0 0 0 rgba(0,0,0,.12)!important;
}

.b-application .v-card {
  border-width: thin;
  display: block;
  max-width: 100%;
  outline: none;
  text-decoration: none;
  -webkit-transition-property: opacity, -webkit-box-shadow;
  transition-property: opacity, -webkit-box-shadow;
  transition-property: box-shadow, opacity;
  transition-property: box-shadow, opacity, -webkit-box-shadow;
  overflow-wrap: break-word;
  position: relative;
  white-space: normal;
  background-color: #fff;
  color: rgba(0, 0, 0, .87);
  border-radius: 0.8rem;
  border: unset;
}

.b-application .card-header {
  padding: 1.25rem 0;
  margin-bottom: 0;
  background-color: transparent;
  font-weight: 600;
  border-bottom: 1px solid #c8ced3;
  color: #333;
  font-size: 16px;
  font-family: sans-serif;
}

.hover-shadow, .b-application .hover-shadow:hover {
  -webkit-transition: -webkit-box-shadow .5s ease-in-out;
  transition: -webkit-box-shadow .5s ease-in-out;
  transition: box-shadow .5s ease-in-out;
  transition: box-shadow .5s ease-in-out, -webkit-box-shadow .5s ease-in-out;
}
.b-application .hover-shadow:hover {
  -webkit-box-shadow: .2em .2em .2em 0 rgba(0,0,0,.25)!important;
  box-shadow: .2em .2em .2em 0 rgba(0,0,0,.25)!important;
  -webkit-box-shadow: rgba(194,198,199,.4) 0 0 10px 0!important;
  box-shadow: 0 0 10px 0 rgba(194,198,199,.4)!important;
}

.hide {
  display: none;
}

.error{
  color: red;
}

.swal2-title {
  font-size: 18px !important;
}
.swal2-styled.swal2-confirm {
  background-color: #ff003e !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeIn {
  animation: fadeIn 300ms ease-in-out;
}

.fadeOut {
  animation: fadeOut 300ms ease-in-out;
}

/* CSS untuk menyembunyikan panah naik-turun pada input number */
// input[type=number]::-webkit-inner-spin-button,
// input[type=number]::-webkit-outer-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
// }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #d82020;
  border-color: #d82020;
}

@media (min-width: 992px){
  .brand-minimized .app-header .navbar-brand {
    width: 65px;
    background-color: transparent;
  }

  .header-fixed .app-body {
      margin-top: 65px;
  }
}

@media (max-width: 991.98px){
  .app-header .navbar-brand {
    top: 5px;
    left: 45%;
  }
  
}

@media (max-width: 767.98px){
  .img-avatar {
    width: 35px;
  }
  .main .container-fluid {
      padding: 0 20px;
  }
}


@media (min-width: 768px){
  .input-scale{
    width: 80%;
  }
}

@media (min-width: 992px){
  .input-scale{
    width: 60%;
  }
}

@media (max-width: 425px){
  .app-header .navbar-brand {
    left: 40%;
  }

  .img-avatar {
    width: 35px;
  }

  .referral{
    font-size: 7px;
  }
}



