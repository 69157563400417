body {
  margin: 0;
  padding: 0;
}
.Select-arrow {
  display: none !important;
}
.tree_cont {
  position: relative;
  margin: 0 0 0 0;
  padding: 10px 0;
  font: 12px Arial;
  color: #555;
  text-align: center;
}
.tree_cont a {
  display: block;
  color: #555;
  text-decoration: none;
}
.tree_cont a:hover {
  color: #444;
  text-decoration: underline;
}

.tree_cont .tree_row {
  margin: 0;
  padding: 0;
}
.tree_cont .box {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0 0 10px 0;
  width: 12%;
  height: auto;
  border: 1px solid #bfc2c5;
  border-radius: 5px;
  background: #f6f8fa;
  background: -moz-radial-gradient(top, #fff 30%, #fff 100%);
  background: -webkit-radial-gradient(top, #fff 30%, #fff 100%);
  background: -ms-radial-gradient(top, #fff 30%, #fff 100%);
  background: -o-radial-gradient(top, #fff 30%, #fff 100%);
  background: radial-gradient(top, #fff 30%, #fff 100%);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}
.tree_cont .box img.img_icon {
  width: 30%;
}
.tree_cont .box span {
  display: block;
  line-height: 15px;
}
.tree_cont .box strong {
  color: #0775b4;
  padding-bottom: 3px;
  display: inline-block;
}
.tree_cont .box strong:hover {
  text-decoration: none;
}

.tree_cont .box span.aLeft {
  padding: 0 5px 0 5px;
  line-height: 18px;
  text-align: right;
}
.tree_cont .box span.aLeft sub {
  display: inline-block;
  float: left;
  width: 13px;
  height: 18px;
  font-size: 12px;
  font-family: Arial;
}
.tree_cont .box span.aLeft code {
  float: right;
  margin: 0 5px;
}
.tree_cont .box span.aLeft img {
  float: left;
  margin: 0 5px;
}

.tree_cont .box .img {
  position: relative;
  display: block;
  margin: 5px auto;
  width: 50px;
  height: 50px;
  max-width: 90%;
  border: 3px solid #286ca2;
  background: #fff;
  border-radius: 100%;
  transition: 300ms;
}
.tree_cont .box:hover .img {
  z-index: 1;
  transform: rotateY(180deg);
}
.tree_cont .box .img img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  opacity: 1;
  z-index: 2;
  transition: 1s;
}
.tree_cont .box .img li img {
  width: 14px;
  height: auto;
}
.tree_cont .box:hover .img img {
  opacity: 1;
  z-index: 1;
  transform: rotateY(180deg);
}
.tree_cont .box .img img:hover {
}
.tree_cont .box .img sub {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  font-size: 11px;
  line-height: 50px;
  opacity: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  transform: rotateY(180deg);
  transition: 1.5s;
  color: #fff;
  font-weight: bold;
  text-shadow: 0 1px rgba(0, 0, 0, 0.5); /*overflow:hidden;*/
  white-space: nowrap;
}
.tree_cont .box .img sub img {
  margin-top: 30%;
  width: 40%;
  height: 40%;
}
.tree_cont .box:hover .img sub {
  opacity: 1;
  z-index: 2;
} /**/
.tree_cont .box:hover .img sub img {
  -moz-transform: rotateY(0);
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}

.tree_cont .box .img.bronze {
  border-color: #824b04;
}
.tree_cont .box .img.bronze sub {
  color: #824b04;
}

.tree_cont .box .img.silver {
  border-color: #d5d5d5;
}
.tree_cont .box .img.silver sub {
  color: #fff;
}

.tree_cont .box .img.gold {
  border-color: #e0a354;
}
.tree_cont .box .img.gold sub {
  color: #e0a354;
}
.tree_cont .box .img.platinum {
  border-color: #6c6d79;
}
.tree_cont .box .img.platinum sub {
  color: #ddd;
}

.tree_cont .box .img.red {
  border-color: #d83a38;
}
.tree_cont .box .img.red sub {
  color: #d83a38;
}
.tree_cont .box .img.green {
  border-color: #5fb000;
}
.tree_cont .box .img.green sub {
  color: #5fb000;
}
.tree_cont .box .img.yellow {
  border-color: #ff9;
}
.tree_cont .box .img.yellow sub {
  color: #ff9;
}
.tree_cont .box .img.blue {
  border-color: #00a1ec;
}
.tree_cont .box .img.blue sub {
  color: #00a1ec;
}
.tree_cont .box .img.purple {
  border-color: #9548fe;
}
.tree_cont .box .img.purple sub {
  color: #9548fe;
}
.tree_cont .box .img.pink {
  border-color: #fa2ad0;
}
.tree_cont .box .img.pink sub {
  color: #fa2ad0;
}

.tree_cont .box .img:hover {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.row1 .box .img {
  width: 70px;
  height: 70px;
}
.row1 .box .img sub {
  font-size: 13px;
  line-height: 70px;
}

/*.tree_cont a.slide_popup	{ color:#555; text-decoration:none; display:block; position:absolute; width:100%; height:100%; }*/
.row1 .box {
  width: 12%;
  min-height: 230px;
}
.row2 .box {
  width: 24%;
  min-height: 230px;
}
.row3 .box {
  width: 48%;
  min-height: 230px;
}
.row4 .box {
  width: 90%;
  min-height: 230px;
}
.row5 .box {
  width: auto;
  height: auto;
  padding: 0;
  border-radius: 100%;
  background: none;
  border: none;
}
.row5 .box img.img_icon {
  width: 50%;
}
.row5 .box .img {
  margin: 0 auto;
  width: 40px;
  height: 40px;
}

// .tree_row .box:hover {
//   background: #fafcfe;
//   background: -moz-radial-gradient(top, #fafcfe 30%, #f0f2f4 100%);
// }
// .tree_row .box:hover {
//   border-color: #8eb2d0;
// }

.tree_cont .bc2,
.tree_cont .bc4,
.tree_cont .bc8,
.tree_cont .bc16,
.tree_cont .bc32 {
  float: left;
  margin: 0;
  padding: 0;
  width: 50%;
}
.tree_cont .bc2 {
  width: 50%;
}
.tree_cont .bc4 {
  width: 25%;
}
.tree_cont .bc8 {
  width: 12.5%;
}
.tree_cont .bc16 {
  width: 6.25%;
}
.tree_cont .bc32 {
  width: 3.125%;
}

.tree_cont .border_none {
  margin: 0;
  padding: 0;
  height: 30px;
}
.tree_cont .border_top {
  margin: 0;
  padding: 0;
  height: 28px;
  border-top: 2px solid #bfc2c5;
}
.tree_cont .border_bottom {
  margin: 0;
  padding: 0;
  height: 28px;
  border-bottom: 2px solid #bfc2c5;
}
.tree_cont .border_left {
  margin: 0;
  padding: 0;
  height: 30px;
  border-left: 2px solid #bfc2c5;
  background: url(/assets/img/backend/border_left_bg.png) no-repeat left bottom;
}
.tree_cont .border_right {
  margin: 0;
  padding: 0;
  height: 30px;
  border-right: 2px solid #bfc2c5;
  background: url(/assets/img/backend/border_right_bg.png) no-repeat right
    bottom;
}

.tree_cont .arw_left {
  background: url(/assets/img/backend/border_left_bg.png) no-repeat left bottom;
}
.tree_cont .arw_right {
  background: url(/assets/img/backend/border_right_bg.png) no-repeat right
    bottom;
}

.tree_row .border_none,
.tree_row .border_top,
.tree_row .border_top,
.tree_row .border_bottom,
.tree_row .border_left,
.tree_row .border_right {
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}
/*.tree_row:hover .border_none,.tree_row:hover .border_top,
.tree_row:hover .border_top,.tree_row:hover .border_bottom,
.tree_row:hover .border_left,.tree_row:hover .border_right	{ border-color:#0775b4; }*/

.tree_popup {
  display: none;
  position: absolute;
  left: -250%;
  top: 65px;
  padding-top: 7px;
  width: 600%;
  color: #bbb;
  z-index: 1000;
  background: url(/assets/img/backend/arrow_popup.png) no-repeat center top;
}
.tree_cont .box .img:hover .tree_popup {
  display: block;
  z-index: 1000;
  transform: rotateY(180deg);
}
.tree_popup .popup_box {
  margin: 0;
  padding: 10px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.8);
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}
.tree_popup .popup_box:hover {
  background: rgba(0, 0, 0, 0.9);
}
.tree_popup .popup_box p {
  margin: 0 0 5px 0;
  padding: 0;
  line-height: 17px;
}

.popup_box ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: normal;
}
.popup_box ul li {
  margin: 0;
  padding: 0;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}
.popup_box ul li:nth-of-type(even) {
  background: rgba(102, 102, 102, 0.2);
}
.popup_box ul li:hover {
  background: rgba(102, 102, 102, 0.4);
}
.popup_box ul li label {
  float: left;
  display: block;
  padding: 5px 0;
  width: 55%;
  text-align: right;
}
.popup_box ul li label code {
  float: right;
  margin: 0 5px;
}
.popup_box ul li span {
  float: left;
  display: block;
  padding: 5px 0;
  width: 45%;
  text-align: left;
  cursor: default;
}
.popup_box ul li span img {
  width: 14px;
  height: 14px;
  vertical-align: middle;
}

.popup_box ul li.aLeft label {
  text-indent: 5px;
  text-align: left;
}
.tree_cont .box:hover .img .popup_box ul li img {
  transform: rotateY(0);
}

/*.tree_popup.display_left	{ left:-10%; background-position:23% top; }
.tree_popup.display_right	{ left:auto; right:-10%; background-position:77% top; }*/

.row4 .box .tree_popup.display_left {
  left: -400%;
  background-position: 23% top;
}
.row4 .box .tree_popup.display_right {
  left: auto;
  right: -400%;
  background-position: 77% top;
}

.row5 .box .tree_popup {
  left: -300%;
  top: auto;
  bottom: 100%;
  padding: 0 0 7px 0;
  width: 700%;
  background: url(/assets/img/backend/arrow_popup2.png) no-repeat center bottom;
}
.row5 .box .tree_popup.display_left {
  left: -550%;
  background-position: 13% top;
}
.row5 .box .tree_popup.display_right {
  left: auto;
  right: -550%;
  background-position: 87% bottom;
}
.row5 .bc16:nth-of-type(2) .box .tree_popup {
  left: -350%;
  background-position: 42% bottom;
}
.row5 .bc16:nth-of-type(15) .box .tree_popup {
  left: auto;
  right: -350%;
  background-position: 58% bottom;
}

.slide_effect .slide_popup .img > div {
  display: block !important;
  visibility: hidden;
  -ms-filter: "alpha(opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transform: translate3d(0, 50px, 0);
  -moz-transform: translateY(50px);
  -o-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: visibility 0s linear 0.2s, -webkit-transform 0.2s linear,
    opacity 0.2s linear;
  -moz-transition: visibility 0s linear 0.2s, -moz-transform 0.2s linear,
    opacity 0.2s linear;
  -o-transition: visibility 0s linear 0.2s, -o-transform 0.2s linear,
    opacity 0.2s linear;
  -ms-transition: visibility 0s linear 0.2s, -ms-transform 0.2s linear,
    opacity 0.2s linear;
  transition: visibility 0s linear 0.2s, transform 0.2s linear,
    opacity 0.2s linear;
}
.slide_effect .slide_popup .img:hover > div {
  visibility: visible;
  -ms-filter: "alpha(opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  -ms-transition-delay: 0s;
  transition-delay: 0s;
}
.slide_effect > .slide_popup > a .bubble-top {
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  -o-transition: -o-transform 0.3s;
  -ms-transition: -ms-transform 0.3s;
  transition: transform 0.3s;
}
.slide_effect > .slide_popup > a:hover .bubble-top {
  -webkit-transform: translate3d(0, -3px, 0);
  -moz-transform: translateY(-3px);
  -o-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  transform: translateY(-3px);
}

.clearBoth {
  margin: 0;
  padding: 0;
  clear: both;
}

.button_up {
  margin: 10px auto;
  padding: 0;
  width: 40px;
  height: 40px;
  background: url(/assets/img/backend/button_up.png) no-repeat 0 top;
}
.button_up a {
  display: block;
  height: 40px;
}
.button_up:hover {
  background-position: 0 bottom;
}
.button_up:active {
  background-position: 0 top;
}

@media only screen and (min-width: 768px)  {
  .row1 .box {
    width: 19%;
  }
  .row2 .box {
    width: 37%; /*min-height:135px;*/
  }
  .row3 .box {
    width: 73%; /*min-height:135px;*/
  }

  .row5 .box .img {
    margin: 0 auto;
    width: 33px;
    height: 33px;
    border: 2px solid;
  }
  .row5 .box .img sub {
    display: none;
  }

  .row3 .box .tree_popup.display_left {
    left: -380%;
    background-position: 27% top;
  }
  .row3 .box .tree_popup.display_right {
    left: auto;
    right: -380%;
    background-position: 73% top;
  }

  .row4 .box .tree_popup.display_left {
    left: -450%;
    background-position: 13% top;
  }
  .row4 .box .tree_popup.display_right {
    left: auto;
    right: -450%;
    background-position: 87% top;
  }
  .row4 .bc8:nth-of-type(2) .box .tree_popup {
    left: -300%;
    background-position: 41% top;
  }
  .row4 .bc8:nth-of-type(7) .box .tree_popup {
    left: auto;
    right: -300%;
    background-position: 59% top;
  }

  .row5 .box .tree_popup.display_left {
    left: -590%;
    background-position: 5% bottom;
  }
  .row5 .box .tree_popup.display_right {
    left: auto;
    right: -590%;
    background-position: 95% bottom;
  }
  .row5 .bc16:nth-of-type(2) .box .tree_popup {
    left: -450%;
    background-position: 25% bottom;
  }
  .row5 .bc16:nth-of-type(3) .box .tree_popup {
    left: -330%;
    background-position: 45% bottom;
  }
  .row5 .bc16:nth-of-type(14) .box .tree_popup {
    left: auto;
    right: -330%;
    background-position: 55% bottom;
  }
  .row5 .bc16:nth-of-type(15) .box .tree_popup {
    left: auto;
    right: -450%;
    background-position: 75% bottom;
  }
}

@media only screen and (min-width: 768px) and (max-width: 830px) {
  .row1 .box {
    width: 25%;
  }
  .row2 .box {
    width: 49%;
  }
  .row3 .box {
    width: 80%;
  }

  .row5 .box .img {
    margin: 0 auto;
    width: 30px;
    height: 30px;
    border: 1px solid;
  }
  .row5 .box .img sub {
    display: none;
  }

  .row5 .box .tree_popup {
    left: -450%;
    width: 1000%;
  }
  .row5 .box .tree_popup.display_left {
    left: -890%;
    background-position: 4% bottom;
  }
  .row5 .box .tree_popup.display_right {
    left: auto;
    right: -890%;
    background-position: 96% bottom;
  }
  .row5 .bc16:nth-of-type(2) .box .tree_popup {
    left: -770%;
    background-position: 17% bottom;
  }
  .row5 .bc16:nth-of-type(3) .box .tree_popup {
    left: -650%;
    background-position: 29% bottom;
  }
  .row5 .bc16:nth-of-type(4) .box .tree_popup {
    left: -530%;
    background-position: 42% bottom;
  }
  .row5 .bc16:nth-of-type(13) .box .tree_popup {
    left: auto;
    right: -530%;
    background-position: 58% bottom;
  }
  .row5 .bc16:nth-of-type(14) .box .tree_popup {
    left: auto;
    right: -650%;
    background-position: 71% bottom;
  }
  .row5 .bc16:nth-of-type(15) .box .tree_popup {
    left: auto;
    right: -770%;
    background-position: 83% bottom;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .tree_cont {
    font-size: 11px;
  }

  .row1 .box {
    width: 19%;
  }
  .row2 .box {
    width: 37%;
  }
  .row3 .box {
    width: 73%;
  }
  .row4 .box,
  .row5 .box {
    padding: 0;
    width: auto;
    height: auto;
    min-height: 0;
    background: none;
    border: none;
  }
  .row4 .box img.img_icon {
    width: 70%;
  }
  .row4 .box span {
    display: none;
  }
  .row4 .box .tree_popup span {
    display: block;
  }

  .row4 .box .img sub,
  .row5 .box .img sub {
    display: none;
  }
  .row4 .box .img {
    margin: 0 auto;
    width: 35px;
    height: 35px;
  }
  .row5 .box .img {
    margin: 0 auto;
    width: 23px;
    height: 23px;
    border: 2px solid;
  }

  .row3 .box .tree_popup.display_left {
    left: -380%;
    background-position: 27% top;
  }
  .row3 .box .tree_popup.display_right {
    left: auto;
    right: -380%;
    background-position: 73% top;
  }

  .row4 .box .tree_popup {
    left: -375%;
    top: 95%;
    width: 850%;
  }
  .row4 .box .tree_popup.display_left {
    left: -680%;
    background-position: 12% top;
  }
  .row4 .box .tree_popup.display_right {
    left: auto;
    right: -680%;
    background-position: 88% top;
  }
  .row4 .bc8:nth-of-type(2) .box .tree_popup {
    left: -440%;
    background-position: 42% top;
  }
  .row4 .bc8:nth-of-type(7) .box .tree_popup {
    left: auto;
    right: -440%;
    background-position: 58% top;
  }

  .row5 .box .tree_popup {
    left: -550%;
    width: 1200%;
  }
  .row5 .box .tree_popup.display_left {
    left: -1060%;
    background-position: 5% bottom;
  }
  .row5 .box .tree_popup.display_right {
    left: auto;
    right: -1060%;
    background-position: 95% bottom;
  }
  .row5 .bc16:nth-of-type(2) .box .tree_popup {
    left: -880%;
    background-position: 22% bottom;
  }
  .row5 .bc16:nth-of-type(3) .box .tree_popup {
    left: -690%;
    background-position: 37% bottom;
  }
  .row5 .bc16:nth-of-type(4) .box .tree_popup {
    left: -510%;
    background-position: 54% bottom;
  }
  .row5 .bc16:nth-of-type(13) .box .tree_popup {
    left: auto;
    right: -510%;
    background-position: 46% bottom;
  }
  .row5 .bc16:nth-of-type(14) .box .tree_popup {
    left: auto;
    right: -690%;
    background-position: 63% bottom;
  }
  .row5 .bc16:nth-of-type(15) .box .tree_popup {
    left: auto;
    right: -880%;
    background-position: 78% bottom;
  }
}

@media only screen and (min-width: 0px) and (max-width: 479px) {
  .tree_cont {
    font-size: 10px;
  }

  .row1 .box {
    width: 40%;
    padding-bottom: 10px;
  }

  .row2 .box {
    width: 60%;
  }
  .row3 .box {
    width: 90%;
  }

  .row4 .box,
  .row5 .box {
    padding: 0;
    width: auto;
    height: auto;
    min-height: 0;
    background: none;
    border: none;
  }
  
  .row4 .box span,
  .row5 .box span {
    display: none;
  }
 
  .row4 .box img.img_icon,
  .row5 .box img.img_icon {
    width: 70%;
  }

  
  .row2 .box .img sub,
  .row3 .box .img sub,
  .row4 .box .img sub,
  .row5 .box .img sub {
    display: none;
  }

  .row4 .box .img {
    margin: 0 auto;
    width: 25px;
    height: 25px;
    border: 2px solid;
  }
  .row5 .box .img {
    margin: 0 auto;
    width: 15px;
    height: 15px;
    border: none;
  }

 
  .row4 .box .tree_popup span {
    display: block;
  }

  .box .tree_popup {
    left: -185%;
    width: 330px;
  }

  .row2 .box .tree_popup {
    top: 90%;
    width: 90%;
  }
  .row2 .bc2:nth-of-type(1) .box .tree_popup {
    left: -510%;
    background-position: 31% top;
  }
  .row2 .bc2:nth-of-type(2) .box .tree_popup {
    left: auto;
    right: -510%;
    background-position: 69% top;
  }

  .row3 .box .tree_popup {
    top: 90%;
    width: 90%;
  }
  .row3 .box .tree_popup.display_left {
    left: -640%;
    background-position: 15% top;
  }
  .row3 .box .tree_popup.display_right {
    left: auto;
    right: -640%;
    background-position: 85% top;
  }
  .row3 .bc4:nth-of-type(2) .box .tree_popup {
    left: -380%;
    background-position: 48% top;
  }
  .row3 .bc4:nth-of-type(3) .box .tree_popup {
    left: auto;
    right: -380%;
    background-position: 52% top;
  }

  .row4 .box .tree_popup {
    top: 90%;
    width: 90%;
  }
  .row4 .box .tree_popup.display_left {
    left: -1160%;
    background-position: 7% top;
  }
  .row4 .box .tree_popup.display_right {
    left: auto;
    right: -1160%;
    background-position: 93% top;
  }
  .row4 .bc8:nth-of-type(2) .box .tree_popup {
    left: -950%;
    background-position: 23% top;
  }
  .row4 .bc8:nth-of-type(3) .box .tree_popup {
    left: -740%;
    background-position: 40% top;
  }
  .row4 .bc8:nth-of-type(4) .box .tree_popup {
    left: -530%;
    background-position: 56% top;
  }
  .row4 .bc8:nth-of-type(5) .box .tree_popup {
    left: auto;
    right: -530%;
    background-position: 44% top;
  }
  .row4 .bc8:nth-of-type(6) .box .tree_popup {
    left: auto;
    right: -740%;
    background-position: 60% top;
  }
  .row4 .bc8:nth-of-type(7) .box .tree_popup {
    left: auto;
    right: -950%;
    background-position: 77% top;
  }

  .row5 .box .tree_popup {
    left: -185%;
    width: 300px;
  }
  .row5 .box .tree_popup.display_left {
    left: -2080%;
    background-position: 2% bottom;
  }
  .row5 .box .tree_popup.display_right {
    left: auto;
    right: -2080%;
    background-position: 98% bottom;
  }
  .row5 .bc16:nth-of-type(2) .box .tree_popup {
    left: -1880%;
    background-position: 12% bottom;
  }
  .row5 .bc16:nth-of-type(3) .box .tree_popup {
    left: -1680%;
    background-position: 21% bottom;
  }
  .row5 .bc16:nth-of-type(4) .box .tree_popup {
    left: -1480%;
    background-position: 30% bottom;
  }
  .row5 .bc16:nth-of-type(5) .box .tree_popup {
    left: -1290%;
    background-position: 39% bottom;
  }
  .row5 .bc16:nth-of-type(6) .box .tree_popup {
    left: -1100%;
    background-position: 48% bottom;
  }
  .row5 .bc16:nth-of-type(7) .box .tree_popup {
    left: -910%;
    background-position: 57% bottom;
  }
  .row5 .bc16:nth-of-type(8) .box .tree_popup {
    left: -700%;
    background-position: 67% bottom;
  }
  .row5 .bc16:nth-of-type(9) .box .tree_popup {
    left: auto;
    right: -700%;
    background-position: 33% bottom;
  }
  .row5 .bc16:nth-of-type(10) .box .tree_popup {
    left: auto;
    right: -910%;
    background-position: 43% bottom;
  }
  .row5 .bc16:nth-of-type(11) .box .tree_popup {
    left: auto;
    right: -1100%;
    background-position: 52% bottom;
  }
  .row5 .bc16:nth-of-type(12) .box .tree_popup {
    left: auto;
    right: -1290%;
    background-position: 61% bottom;
  }
  .row5 .bc16:nth-of-type(13) .box .tree_popup {
    left: auto;
    right: -1480%;
    background-position: 70% bottom;
  }
  .row5 .bc16:nth-of-type(14) .box .tree_popup {
    left: auto;
    right: -1680%;
    background-position: 79% bottom;
  }
  .row5 .bc16:nth-of-type(15) .box .tree_popup {
    left: auto;
    right: -1880%;
    background-position: 88% bottom;
  }
}

/*---------------------------------------------------------{ border row 1 }------------------------------------------------------------------------*/
.row1 a:hover + .clearBoth + .bc2 .border_right,
.row1 a:hover + .clearBoth + .bc2 + .bc2 + .clearBoth + .bc4 .border_right,
.row1 a:hover + .clearBoth + .bc2 + .bc2 + .clearBoth + .bc4 + .bc4 .border_top,
.row1
  a:hover
  + .clearBoth
  + .bc2
  + .bc2
  + .clearBoth
  + .bc4
  + .bc4
  + .bc4
  .border_top,
.row1
  a:hover
  + .clearBoth
  + .bc2
  + .bc2
  + .clearBoth
  + .bc4
  + .bc4
  + .bc4
  + .bc4
  .border_left {
  border-color: #4299cf;
}

/*---------------------------------------------------------{ border row 2 }------------------------------------------------------------------------*/
.row2 .bc2:hover + .bc2 + .clearBoth + .bc4 .border_right,
.row2
  .bc2:hover
  + .bc2
  + .clearBoth
  + .bc4
  + .bc4
  + .bc4
  + .bc4
  + .clearBoth
  + .bc8
  .border_right,
.row2
  .bc2:hover
  + .bc2
  + .clearBoth
  + .bc4
  + .bc4
  + .bc4
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  .border_top,
.row2
  .bc2:hover
  + .bc2
  + .clearBoth
  + .bc4
  + .bc4
  + .bc4
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  .border_top,
.row2
  .bc2:hover
  + .bc2
  + .clearBoth
  + .bc4
  + .bc4
  + .bc4
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  .border_left {
  border-color: #4299cf;
}

.row2 .bc2 + .bc2:hover + .clearBoth + .bc4 + .bc4 + .bc4 + .bc4 .border_left,
.row2
  .bc2
  + .bc2:hover
  + .clearBoth
  + .bc4
  + .bc4
  + .bc4
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  .border_right,
.row2
  .bc2
  + .bc2:hover
  + .clearBoth
  + .bc4
  + .bc4
  + .bc4
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  .border_top,
.row2
  .bc2
  + .bc2:hover
  + .clearBoth
  + .bc4
  + .bc4
  + .bc4
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  .border_top,
.row2
  .bc2
  + .bc2:hover
  + .clearBoth
  + .bc4
  + .bc4
  + .bc4
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  .border_left {
  border-color: #4299cf;
}

/*---------------------------------------------------------{ border row 3 }------------------------------------------------------------------------*/
.row3 .bc4:hover + .bc4 + .bc4 + .bc4 + .clearBoth + .bc8 .border_right,
.row3
  .bc4:hover
  + .bc4
  + .bc4
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  .border_right,
.row3
  .bc4:hover
  + .bc4
  + .bc4
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  .border_top,
.row3
  .bc4:hover
  + .bc4
  + .bc4
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  .border_top,
.row3
  .bc4:hover
  + .bc4
  + .bc4
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_left {
  border-color: #4299cf;
}

.row3
  .bc4
  + .bc4:hover
  + .bc4
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  .border_left,
.row3
  .bc4
  + .bc4:hover
  + .bc4
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_right,
.row3
  .bc4
  + .bc4:hover
  + .bc4
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_top,
.row3
  .bc4
  + .bc4:hover
  + .bc4
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_top,
.row3
  .bc4
  + .bc4:hover
  + .bc4
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_left {
  border-color: #4299cf;
}

.row3
  .bc4
  + .bc4
  + .bc4:hover
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  .border_right,
.row3
  .bc4
  + .bc4
  + .bc4:hover
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_right,
.row3
  .bc4
  + .bc4
  + .bc4:hover
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_top,
.row3
  .bc4
  + .bc4
  + .bc4:hover
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_top,
.row3
  .bc4
  + .bc4
  + .bc4:hover
  + .bc4
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_left {
  border-color: #4299cf;
}

.row3
  .bc4
  + .bc4
  + .bc4
  + .bc4:hover
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  .border_left,
.row3
  .bc4
  + .bc4
  + .bc4
  + .bc4:hover
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_right,
.row3
  .bc4
  + .bc4
  + .bc4
  + .bc4:hover
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_top,
.row3
  .bc4
  + .bc4
  + .bc4
  + .bc4:hover
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_top,
.row3
  .bc4
  + .bc4
  + .bc4
  + .bc4:hover
  + .clearBoth
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_left {
  border-color: #4299cf;
}

/*---------------------------------------------------------{ border row 4 }------------------------------------------------------------------------*/
.row4
  .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  .border_right,
.row4
  .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  .border_right,
.row4
  .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  .border_top,
.row4
  .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  .border_top,
.row4
  .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_left {
  border-color: #4299cf;
}

.row4
  .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_left,
.row4
  .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_right,
.row4
  .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_top,
.row4
  .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_top,
.row4
  .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_left {
  border-color: #4299cf;
}

.row4
  .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_right,
.row4
  .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_right,
.row4
  .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_top,
.row4
  .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_top,
.row4
  .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_left {
  border-color: #4299cf;
}

.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_left,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_right,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_top,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_top,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_left {
  border-color: #4299cf;
}

.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_right,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_right,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_top,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_top,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_left {
  border-color: #4299cf;
}

.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_left,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_right,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_top,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_top,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_left {
  border-color: #4299cf;
}

.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_right,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_right,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_top,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_top,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .bc8
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_left {
  border-color: #4299cf;
}

.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  .border_left,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_right,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_top,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_top,
.row4
  .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8
  + .bc8:hover
  + .clearBoth
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .bc16
  + .clearBoth
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  + .bc32
  .border_left {
  border-color: #4299cf;
}

.row5 .bc16 .box span {
  display: none;
}
.row5 .bc16 .box .popup_box span {
  display: block;
}
.row5 .bc16 .box span.img {
  display: block;
}
.tree_cont .box .button {
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  color: #fff;
  white-space: normal;
  border-radius: 3px;
}
.tree_cont .box .button:hover {
  text-decoration: none;
}
.button_style {
  max-width: 190px;
}
.slide_effect .slide_popup:hover > div.button_style {
  visibility: hidden;
}
.row5.slide_effect .slide_popup:hover > div.button_style {
  visibility: visible;
}
@media (max-width: 479px) {
  .slide_effect .slide_popup:hover > div.button_style {
    visibility: visible;
  }
}
