/*Header Section*/
.header-profil {
  display: inline-block;
  height: 55px;
  width: 55px;
  background-color: #dce6ff;
  position: relative;
  padding: 15px;
  margin: 0px 100px 0px 15px;
  border-radius: 110px;
}

.header-title-pp {
  font-size: 25px;
  background-color: transparent;
  font-weight: 700;
  color: #5774c4;
}

/*Home Section*/
.home .card-header {
  padding: 0 0 1.25rem 0;
  margin-bottom: 0;
  background-color: transparent;
  font-weight: 100;
  border-bottom: unset;
  color: #5b5b5b;
  font-size: 16px;
  font-family: sans-serif;
}

.foto-profil {
  display: inline-block;
  height: 130px;
  width: 130px;
  background-color: #dce6ff;
  position: relative;
  padding: 15px;
  margin: 15px;
  border-radius: 110px;
}

.cover-pp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.title-pp {
  font-size: 60px;
  background-color: transparent;
  font-weight: 700;
  color: #5774c4;
}

.info-profil {
  margin: 15px 0;
}

.info-profil .title {
  font-size: 20px;
  font-family: sans-serif;
  font-weight: 600;
  color: #3554a6;
}


.info-profil .role {
  color: #FF5722;
  font-weight: 500;
}

.info-profil p {
    margin-top: 0;
    margin-bottom: 0;
}

.info-profil .detail {
  margin-top: 15px;
}

.info-profil p.value {
  font-weight: 600;
  font-size: 14px;
}

.info-profil p.label {
  font-size: 12px;
  color: grey;
}

.info-profil p.value_aktif {
  font-size: 13px;
  color: #076169;
  font-weight: 500;
}
.info-profil p.value_expired {
  font-size: 13px;
  color: #f86c6b;
  font-weight: 500;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.alertExpired .value,
.alertExpired .value_expired,
.alertExpired .value_aktif {
  animation: blink 0.5s step-start infinite;
}

.order-pin {
  min-height: 212px;
}
@media (max-width: 425px) {
  .foto-profil {
    display: inline-block;
    height: 100px;
    width: 100px;
    background-color: #dce6ff;
    position: relative;
    padding: 15px;
    margin: 15px 0;
    border-radius: 110px;
  }

  .title-pp {
    font-size: 50px;
    background-color: transparent;
    font-weight: 700;
    color: #5774c4;
  }

  /*Header Section*/
  .header-profil {
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: #dce6ff;
    position: relative;
    padding: 15px;
    margin: 0px 25px 0px 100px;
    border-radius: 110px;
  }

  .header-title-pp {
    font-size: 18px;
    background-color: transparent;
    font-weight: 700;
    color: #5774c4;
  }
}

