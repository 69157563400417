.login {
  min-height: 980px;
  overflow: auto;
  background-image: url('../assets/img/brand/B Star web final background-02-min.jpg') !important;
  background-position: 50% !important;
  background-size: cover !important;
}

.container.fill-height {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 170px auto;
}

.login .section-slider {
  // padding: 50px;
  position: relative;
  width: 100%;
  height: 930px;
  .body-slider {
    padding-top: 150px;
  }
  .footer-slider {
    padding-top: 220px;
  }
  .background-img {
    width: 100%;
    height: 100%;
  }
  .background-img-mob {
    display: none;
  }
}

.logo-login {
  height: 150px;
}

.container-login {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  top: 0;

  .bstar-logo {
    width: 100%;
    // margin-left: 20%;
  }

  .form-login {
    height: 100%;
    width: 550px;
    margin: 0 auto;
  }
}

.section-login {
  padding: 30px 60px;
  border-radius: 5px;
  background: rgb(255 255 255 / 87%);
  // .input-radius {
  //   border-radius: 0 15px 15px 0 !important;
  // }
  // .icon-radius {
  //   border-radius: 15px 0 0 15px !important;
  // }
  // .btn-rounded {
  //   border-radius: 20px !important;
  // }
  .icon-bg {
    background-color: transparent;
  }

  .icon-bg i{
    color: rgb(35, 40, 44); // Mengubah warna border-bottom saat focus
    transition: color 0.5s ease;
  }

  .input-bg {
    background-color: transparent;

    &::placeholder {
      color: #a5a5a5;
    }
    border-bottom: 1px solid #5e5e5e;
  }
  
  .form-control {
    color: #5c6873;
    border: none;
    border-bottom: 1px solid #5d5d5d; // Memberikan border hanya pada bagian bawah
    outline: none;
    box-shadow: none; // Menghilangkan box-shadow
    transition: border-color 0.5s ease; // Transisi saat mengubah warna border
  }

  .form-control:focus {
      border-color: #2196F3; // Mengubah warna border-bottom saat focus
      box-shadow: 0 4px 8px rgba(0, 89, 255, 0.1); // Memberikan shadow hanya di bagian bawah
  }

  .input-group:focus-within .icon-bg i{
    color: #2196F3; /* Ganti dengan warna yang Anda inginkan saat fokus */
  }

  .body-header {
    padding-top: 30px;
  }
  .body-login {
    padding-top: 20px;
  }
  .footer-login {
    margin-top: 50px;
    width: 100%;
    padding-left: 55px;
    padding-right: 55px;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    // justify-content: space-between;

    &--version {
      padding-top: 5px;
      font-size: 12px;
      width: 100%;
      text-align: center;
    }
    &--support {
      text-align: center;
      .logo-support {
        width: 80px;
      }
    }
  }
}

footer {
  background: #ffffff;
  padding: 15px;
  color: #F44336;
  font-family: sans-serif;
  font-size: 16px;
}

@media (max-width: 768px) {
  .login {
    min-height: 568px;
    overflow: auto;
    background-image: url('../assets/img/brand/B Star web final background-02-mob.jpg') !important;
    background-position: 0% !important;
    background-size: cover !important;
  }
  .section-slider {
    position: relative;
    width: 100%;
    height: 813px;

    .background-img-mob {
      display: block;
      width: 100%;
      height: 813px;
    }

    .background-img {
      display: none;
    }
  }

  .container.fill-height {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 60px auto;
  }

  .logo-login {
    height: 150px;
  }

  .container-login {
    position: absolute;
    margin: 0 auto;
    width: 100%;
    height: 813px;
    top: 0;

    .bstar-logo {
      width: 90%;
      // margin-left: 20%;
    }
    .form-login {
      height: 100%;
      width: 430px;
      margin: 0 auto;
    }
  }

  .section-login {
    position: relative;
    width: 100%;
    .body-header {
      padding-top: 30px;
    }
    .body-login {
      padding-top: 30px;

      h3 {
        font-size: 18px;
      }

      p {
        font-size: 12px;
      }
    }
    .footer-login {
      margin-top: 50px;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      display: flex;
      flex-direction: column;
      align-content: stretch;
      // justify-content: space-between;

      &--version {
        padding-top: 5px;
        font-size: 12px;
        width: 100%;
        text-align: center;
      }
      &--support {
        text-align: center;
      }
      .logo-support {
        width: 60px;
      }
    }
  }
}
