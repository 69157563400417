.gradient-button {
  background: linear-gradient(90deg, #ff5a29, red);
  color: #fff;
  font-weight: 800;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}

.gradient-button:hover {
  color: #fff;
  background: linear-gradient(90deg, #f8511e, #d80238);
  box-shadow: 0 2px 10px rgba(0,0,0,.2);
}

.upgrade-member-button {
  background: linear-gradient(90deg, #ffa629, rgb(255, 136, 0));
  color: #fff;
  font-weight: 800;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}

.upgrade-member-button:hover {
  color: #fff;
  background: linear-gradient(90deg, #f87d1e, #d86602);
  box-shadow: 0 2px 10px rgba(0,0,0,.2);
}

.custom-control-label::before {
  top: 0.2rem;
  border: #f44336 solid 1px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #f44336;
  background-color: #f44336;
}

.custom-control-label::after {
  top: 0.2rem;
}

.cover-input-field {
  display: -webkit-inline-flex;
  border: 1px solid #eee;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  -webkit-box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  -moz-box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
}

.cover-button-field {
  display: -webkit-inline-flex;
  border: 1px solid #eee;
  border-radius: 10px;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  background-color: #fff;
  box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  -webkit-box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  -moz-box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
}

.cover-input-field:hover,
.cover-input-field:focus,
.cover-input-field:active, 
.cover-button-field:hover,
.cover-button-field:focus,
.cover-button-field:active {
  color: #5c6873;
  background-color: #fff;
  border-color: #ee8a8a;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(216, 32, 32, 0.25);
}

// .cover-input-field.is-invalid, .cover-button-field.is-invalid {
//   border-color: #f86c6b;
//   padding-right: calc(1.5em + 0.75rem);
//   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f86c6b' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f86c6b' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
//   background-repeat: no-repeat;
//   background-position:  top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
//   background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
// }

.cover-input-field.readonly, .cover-button-field.readonly {
  background-color: #e4e7ea;
}

.custom-input-field {
  margin-left: 25px;
  width: 100%;
  background-color: transparent;
  border: unset;
}
.custom-link-field {
  margin:0 15px;
  border-radius: 10px !important;
  background-color: #fff;
  box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  -webkit-box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  -moz-box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
}

.custom-link-group {
  background-color: #fff;
  box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  -webkit-box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  -moz-box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
}
.custom-link-btn {
  border-radius: 10px !important;
  border: 2px solid;
  font-size: 12px;
  font-weight: 600;
}


.custom-date-field {
  margin-left: 25px;
  width: 100%;
  background-color: transparent;
  border: unset;
}

.custom-input-icons {
  color: #ed1313;
  position: absolute;
  left: 12px;
  top: 10px;
}

.custom-input-field > div {
  border: unset;
  box-shadow:unset;
  background-color: transparent;
}


.custom-input-field > div:hover {
  border: unset;
  box-shadow:unset;
  background-color: transparent;
}

.custom-input-field:focus,
.custom-date-field:focus {
  color: #5c6873;
  background-color: transparent;
  border-color: unset;
  outline: 0;
  box-shadow: unset;
}

.custom-input-field.is-invalid:focus {
  border-color: transparent;
  box-shadow: unset;
}

.custom-date-field.is-invalid {
  padding-right: unset;
  background-image: unset;
  background-repeat: no-repeat;
  background-position: unset;
  background-size: unset;
}

.custom-date-field.is-invalid:focus {
  border-color: transparent;
  box-shadow: unset;
}

.pilihan-premi {
  border: 1px solid #eee;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  -webkit-box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  -moz-box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  padding: 10px;
  cursor: pointer;
}
.jk-custom-field {
  display: inline-block;
  border: 2px solid #ed1313;
  background-color: #fff;
  border-radius: 30px;
  text-align: center;
  width: 45px;
  padding: 8px 0px;
  margin: 0 auto;
  cursor: pointer;
}

.jk-custom-field:hover {
  border: 2px solid #ed1313;
  background-color: #e7e6fa;
}

.jk-custom-icons {
  color: #ed1313;
  font-size: 20px;
}

.jk-custom-field.btn-success {
  background-color: #ffc7c7 !important;
}
.jk-custom-field.btn-primary {
  background-color: #ffffff !important;
}
.jk-custom-field.btn-selected {
  background-color: #ffc7c7 !important;
}

.pilihan-premi .img-size {
  height: 60px;
}

.pilihan-premi .title {
  margin-top: 10px;
}

.custom-input-field .Select-control {
  background-color: transparent;
  border: unset;
}
.custom-input-field .Select-menu-outer {
  background-color: #fff;
  position: absolute;
  left: -22px;
  width: 100%;
}
.custom-input-field .Select-menu-outer > div {
  background-color:#ffbd7c;
  border: 0.2px solid #607D8B;
  font-weight: 600;
  border-radius: 5px;
}

.custom-input-field .Select-menu-outer:hover {
  background-color: #fff;
  position: absolute;
  left: -22px;
  width: 100%;
}

.custom-input-field.is-open .Select-control {
  background-color: transparent;
  border: unset;
}

.custom-input-field .Select-control .Select-clear {
  display: none !important;
}

.custom-input-field .Select-control .Select-arrow {
  display: inline-block !important;
  border-color: #fe0808 transparent transparent;
}

.custom-input-field .Select-arrow-zone:hover > .Select-arrow,
.custom-input-field.is-open .Select-arrow {
  border-top-color: #fe0808;
}

.custom-input-field.is-open .Select-control .Select-arrow {
  border-color: #fe0808 transparent transparent;
}

.custom-input-field.Select.is-focused > .Select-control {
  background: transparent !important;
  border: unset !important;
  box-shadow: unset !important;
}

.custom-input-field .Select-control:focus {
  background: transparent !important;
  border: unset !important;
  box-shadow: unset !important;
}

// .custom-input-field.is-invalid .Select-control .Select-arrow-zone {
//   display: none;
// }

.custom-input-field.has-value .Select-control .Select-arrow-zone {
  display: table-cell;
}

.custom-input-field>.Select-control .Select-value, .Select-placeholder {
  line-height: 37px !important;
}

.mbsc-custom {
  background: transparent;
}

.mbsc-custom .mbsc-select:hover{
  color: #5c6873;
  background-color: #fff;
  border-color: #ee8a8a;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(216, 32, 32, 0.25);
}

.mbsc-custom .mbsc-ios.mbsc-select.mbsc-ltr{
  font-size: 0.875rem;
}

.mbsc-custom .mbsc-ios.mbsc-textfield-wrapper-outline, .mbsc-ios.mbsc-textfield-wrapper-box {
  margin: 0;
}

.mbsc-custom .select2{
  width: 100% !important;
}

.mbsc-custom .select2-selection--single {
  background-color: transparent !important;
  border: unset !important;
  border-radius: unset !important;
  margin: 4px auto !important;
}

.mbsc-custom .select2-selection--single .select2-selection__rendered {
  display: block !important;
  padding-left: 40px !important;
  padding-right: 20px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.mbsc-custom .select2-selection--single .select2-selection__arrow {
  height: 35px !important;
  position: absolute !important;
  top: 1px !important;
  right: 4px !important;
  width: 20px !important;
}

.mbsc-custom .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #F44336 transparent transparent transparent !important;
  border-width: 5px 5px 2.5px !important;
}

.mbsc-custom .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #F44336 transparent !important;
  border-width: 5px 5px 5.5px !important;
  top: 37% !important;
}

.mbsc-custom .select2-selection--single .select2-selection__clear {
  cursor: pointer !important;
  float: right !important;
  font-weight: bold !important;
  height: 31px !important;
  margin-right: 25px !important;
  padding-right: 0px !important;
}

.cover-select-field {
  display: -webkit-inline-flex;
  border: 1px solid #eee;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  -webkit-box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  -moz-box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
}

.cover-select-field:hover,
.cover-select-field:focus,
.cover-select-field:active {
  color: #5c6873;
  background-color: #fff;
  border-color: #ee8a8a;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(216, 32, 32, 0.25);
}

.cover-select-field.is-invalid {
  border-color: #f86c6b;
}

.switch-red .switch-input:checked + .switch-slider {
  background-color: #fff;
  border-color: #ff0000;
}
.switch-red .switch-input:checked + .switch-slider::before {
  background-color: #ff0000;
  border-color: #ff0000;
}

.btn-sponsor, .btn-penempatan{
  text-align: left;
  font-size: 14px;
  color: #aaaaaa;
  overflow: hidden;
}

.detail-sponsor, .detail-penempatan {
  display: inline-block;
  font-size: 11px;
  font-weight: 700;
  background-color: #aaaaaa2b;
  width: 100%;
  padding: 5px 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.card-tertanggung-utama {
  border-radius: 15px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}
.card-tertanggung-utama .card-tu-header {
  border-radius: 15px 15px 0 0;
  padding: 15px;
  background: linear-gradient(to right, #ff5a29, #ff003e);
}
.card-tertanggung-utama .tertanggung-icons {
  color: #ff003e;
  font-size: 25px;
  position: absolute;
  right: 4px;
  top: 4px;
}

.card-unset{
  border: unset;
  box-shadow: unset;
}

.custom-header-logo{
  color: #ed1313;
  font-size: 50px;
}

.custom-header-label {
  display: inline-block;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  background: linear-gradient(to right, #f8511e, #ff0000);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 6px 25%;
}

.tagihan-performa .custom-header-label {
  padding: 6px 15%;
}

.custom-header-group.container {
  display: flex;
}

.custom-header-group .left-div {
  flex: 1;
  background-color: white;
  flex-basis: 1%;
  border-left: 5px solid #f00;
}

.custom-header-group .center-div {
  flex: 1;
  background-color: red;
  flex-basis: 98%;
}

.custom-header-group .right-div {
  flex: 1;
  background-color: white;
  flex-basis: 1%;
  border-right: 5px solid #f00;
}

.custom-header-group.tagihan-performa .left-div {
  flex-basis: 2%;
}

.custom-header-group.tagihan-performa .center-div {
  flex-basis: 96%;
}

.custom-header-group.tagihan-performa .right-div {
  flex-basis: 2%;
}



.radio-kuesioner {
  display: inline-block;
  // border: 1.5px solid #607D8B;
  padding: 0;
  color: red;
  font-size: 14px;
  text-transform: uppercase;
  // border-radius: 10px;
  text-align: center;
  width: 100%;
  font-weight: 600;
}

.radio-kuesioner .custom-control-label {
  width: 100%;
  padding: 15px;
  font-weight: bold;
  background: transparent;
  border-radius: 10px;
  color: red;
  border: 1.5px solid #607D8B;
  cursor: pointer;
}

.radio-kuesioner .custom-control-label::before {
  visibility: hidden;
  position: absolute;
  left: 0;
}

.radio-kuesioner .custom-control-label::after {
  visibility: hidden;
  position: absolute;
  left: 0;
}

.radio-kuesioner input[type="radio"]:checked + label {
  font-weight: bold;
  background: -webkit-gradient(linear, left top, right top, from(#f8511e), to(#ff0000));
  background: -webkit-linear-gradient(left, #f8511e, #ff0000);
  background: linear-gradient(to right, #f8511e, #ff0000);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: #fff;
  border: 1.5px solid #607D8B;
}

.pd-5{
  padding-right: 5px!important;
  padding-left: 5px!important;
}

.group-kuesioner-female{
  font-size: 18px;
  font-weight: 600;
}

.card-print .card-print-header.custom {
  background: unset;
  color: #333;
  padding: 0 0.2rem;
  margin-bottom: 20px;
  font-size: 20px;
}

.card-print-body.custom {
  border: 2px solid #9e9e9e9e;
  box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  -webkit-box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  -moz-box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  border-radius: 10px;
  margin-bottom: 30px;
}

.SubLebel {
  margin: 15px 0;
  display: flex;
  align-items: center;
}

.SubLebel .custom-label-icons {
  color: #ed1313;
  font-size: 24px;
  padding: 0 12px;
}

.SubLebel .custom-label {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.custom-label-report .form-label {
  color: grey;
  font-size: 12px;
  font-weight: 600;
  margin: 3px 0px;
}

.custom-label-report .form-value {
  color: #333;
  font-size: 14px;
  font-weight: 600;
}

hr.custom {
  border-top: 1px solid #f4433669;
}

.custom-ktp-input {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 8px 16px;
  background-color: #ffffff;
  border: 1px solid #a5a5a5;
  border-radius: 15px;
  cursor: pointer;
  color: grey;
  width: 100%;
  text-align: center;
  box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  -webkit-box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
  -moz-box-shadow: 3px 2px 8px -3px rgba(201, 203, 204, 0.61);
}

.custom-ktp-input input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.custom-ktp-input label {
  display: block;
  margin: 0;
}

.custom-ktp-input:hover {
  background-color: #e0e0e0;
}

.canvas-border {
  width: 100%;
  background-color: white;
  position: relative;
  border-radius: 15px;
  border: unset;
}

.canvas-border::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 17px;
  z-index: -1;
  background: linear-gradient(to right, #f8511e, #ff0000);
}

.btn-clear {
  border-radius: 10px;
  border: 2px solid red;
  color: red;
  font-weight: 600;
}

.btn-clear:hover {
  background-color: rgb(241, 212, 212);
  border: 2px solid red;
  color: red;

}

.table-produk-performa {
  border-collapse: separate;
  border-spacing: 0px;
}
.table-produk-performa.table-bordered th, .table-produk-performa.table-bordered td {
  border: 2px solid #656565;
}
.table-produk-performa.table-bordered thead th, .table-produk-performa.table-bordered thead td {
  border-bottom-width: 0px;
}
.table-produk-performa th:first-of-type {
  border-top-left-radius: 10px;
  border-right-width: 0;
}
.table-produk-performa th:last-of-type {
  border-top-right-radius: 10px;
}
.table-produk-performa tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 10px;
  border-right-width: 0;
}
.table-produk-performa tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 10px;
}

.jml-pembayaran-va {
  display: inline-block;
  border: 1px solid #656565;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 6px 10%;
  margin-bottom: 15px;
  font-weight: 800;
}

.clip-va {
  display: inline-block;
  border: 1px solid #656565;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 6px 10%;
  font-weight: 800;
  width: 100%;
}

.selected-produk{
  background: -webkit-gradient(linear, left top, right top, from(#ff5a29), to(#ff0000)) !important;
  background: -webkit-linear-gradient(left, #ff5a29, #ff0000) !important;
  background: linear-gradient(to right, #ff5a29, #ff0000) !important;
  color: #fff !important;
  opacity: 1 !important;
}

.custom-modal .page-item.active .page-link {
  background-color: #F44336;
  border-color: #F44336;
}

.custom-modal .page-link {
  color: #F44336;
}

.custom-modal .modal-footer .btn-primary {
  background: linear-gradient(to right, #ff5a29, #ff0000);
  color: #fff;
  font-weight: 800;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.custom-modal .modal-footer .btn-primary:hover {
  color: #fff;
  // transform: translateY(-2px);
  background: linear-gradient(to right, #f8511e, #d80238);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.konfirmasi-spaj {
  background-color: #ffffff;
}

.canvas-border::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 17px;
  z-index: -1;
  background: linear-gradient(to right, #f8511e, #ff0000);
}

.type-premi {
  display: inline-block;
  background-color: #009688;
  color: white;
  padding: 0 15px;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.type-referral {
  display: inline-block;
  background-color: #db5657;
  color: white;
  padding: 0 15px;
  font-weight: 700;
  border-radius: 50px;
}

.referral .table-fit th, .table-fit td {
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Show ellipsis for overflowing text */
}

.modal-dialog {
  width: 100%;
  max-width: 800px;
}

@media (max-width: 768px) {
  .custom-header-group .left-div {
    flex-basis: 2%;
  }
  
  .custom-header-group .center-div {
    flex-basis: 96%;
  }
  
  .custom-header-group .right-div {
    flex-basis: 2%;
  }
}

@media (max-width: 568px) {
  .gradient-button {
    font-size: 12px;
  }

  .mobile-p0 {
    padding: 0;
  }

  .custom-input-field .Select-menu-outer {
    position: absolute;
    left: -22px;
    width: 105%;
  }

  .custom-header-label {
    font-size: 21px;
    padding: 6px 10%;
  }

  .radio-kuesioner .custom-control-label {
    font-size: 11px;
  }

  .modal-dialog {
    margin: 0;
  }
}
@media (max-width: 413px) {
  .fz-11 {
    font-size: 12px;
  }
  .pilihan-premi .img-size {
    height: 25px;
  }
}

@media (max-width: 425px) {
  .fz-11 {
    font-size: 12px;
    padding: 0;
  }
  .pilihan-premi .img-size {
    height: 25px;
  }
  .img-position{
    padding-left: 10px;
  }
  .col-pilih-premi{
    padding: 0;
  }
}